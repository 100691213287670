@import "@recoinsights/shared/style/Variables.scss";

.questionnaire-component-wrapper{
  .questions-column-wrapper{
    overflow-y:auto;
    height: calc(100vh - 200px);
  }
  .questionnaire-page{
    .ant-collapse-content-box{
      padding: 0;
      .question-wrapper{
        background-color: $page-background;
        padding: 16px;
        & + .question-wrapper{
          margin-top: 20px;
        }

        .add-new-option{
          font-size:25px;
          color:$primary-color;
        }

        .question-input{
          margin-top: 10px;
        }
      }
    }
  }



  .questionnaire-right-panel{
    .question-master-wrapper{
      .masterQuestion-list-wrapper{
        height: calc(100vh - 410px);
        overflow-y: auto;
      }
      .search{
        margin-top:11px;
      }
      .ques-master{
        background-color: $page-background;
        font-size:15px;
        padding:10px 10px;
        margin-top:10px;
        margin-bottom:10px;
        position: relative;

        .question-icon{
          color: $primary-color;
          // position: absolute;
          // right: 10px;
          // bottom: 3px;
          font-size: 18px;
          margin-left: 5px;
        }
      }
    }
    .questionnaire-action-wrapper{
      border-top: 1px solid $primary-color;
      background-color: $white;
      position: sticky;
      bottom: 0;
      .questionnaire-action-btn{
        cursor: pointer;
        padding: 10px;
        font-size: 15px;
        width:40px;
        height:40px;
        border-radius: 50%;
        color:#FFF;
        text-align:center;
        line-height: 1;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }

      .icon-plus{
        background-color: $primary-color;
      }
      .icon-save{
        background-color:$blue;
      } 
      .icon-publish{
        background-color: $black;
      }
    }
  }
}

.ql-editor{
  min-height:125px;
}
.survey-quill{
  background-color: white;
  background-size: contain;

  &.disabled{
    background-color: #f5f5f5;
    cursor: not-allowed;
    .ql-toolbar{
      pointer-events: none;
    }
    .ql-container{
      color: rgba(0, 0, 0, 0.25);
    }
    *{
      cursor: not-allowed;
    }
  }
}

 



