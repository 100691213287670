@import '@recoinsights/shared/style/Variables.scss';

.profile_section {
    display: flex;
    justify-content: space-around;
    h1 {
        font-size: 22px;
        font-weight: 600;
        margin: 0px;
    }
    .submit {
        display: flex;
        justify-content: flex-end;
    }
}
.BenchMarkingInput{
    width:120px;
    margin: 5px;
    height: 25px;
    font-size: 10px;
}
.ScoreRangeInput{
    height: 25px;
    width:60px;
    font-size: 10px;
}
.BenchMarkingSubHead{
    font-size: 10px;
    font-weight: 600;
}
