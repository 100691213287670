@import "@recoinsights/shared/style/Variables.scss";

.Benchmarking-role-wrapper{
    background-color: white;
}

.unitClass{
    font-size: 12px;
    color: #AFAFAF;
}

.bottomLine{
    border-bottom:1px solid #000;
}
