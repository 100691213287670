@import './Variables.scss';

.theme-button {
  color: rgba(#fff, 0.75);
  &.primary:not(.ant-btn-background-ghost) {
    background-color: $primary-color;
  }
  &:hover {
    color: #fff;
  }
  &.full-width {
    width: 100%;
  }
}
.theme-link {
  color: $primary-color !important;
  &:hover {
    color: rgba($primary-color, 0.5);
  }
}

.secondary-link {
  color: $secondary-color !important;
  &:hover {
    color: rgba($secondary-color, 0.5);
  }
}

.default-link {
  color: $link-color !important;
  &:hover {
    color: rgba($link-color, 0.5);
  }
}

.btn-dark{
  background-color: #000;
  color: #fff;
  &:hover{
    background-color: #fff;
    color: #000;
    border: 1px solid #000;
  }
}

.bg-white{
  background-color: #fff;
}

.desktop-layout {
  .ant-layout-header {
    width: 100%;
    background-color: #fff;
    padding: 0 20px;
    height: $header-height;
    z-index: 1001;

    .ant-col {
      height: $header-height;
    }

    .custom-nav-link {
      color: #000;
      cursor: pointer;
      padding: 0 20px;
      height: $header-height;
      line-height: $header-height;
      display: flex;
      align-items: center;

      .logo-text{
        color: #fff;
        margin-left: 20px;
        font-size: 1.7rem;
      }

      .login-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        .ant-space-item {
          height: 100%;
        }

        .flag {
          background-color: #666666;
          width: 40px;
          height: 100%;
        }

        .login-rows {
          .row-1 {
            font-size: 14px;
            line-height: 16px;
          }

          .row-2 {
            font-size: 12px;
            line-height: 14px;
          }
        }
      }

      &.account-details {
        display: flex;
        //flex-direction: column;
        justify-content: center;
        height: 100%;
        align-items: center;

        span {
          /*line-height: 13px;
          font-size: 11px;*/
          font-weight: bold;
        }

        .group-logo{
          max-height: 60px;
          padding: 4px;
        }
      }

      span {
        font-weight: 300;
        vertical-align: middle;

        &.anticon{
          svg {
            font-size: 14px;
            margin-bottom: -3px;
            margin-left: 8px;
          }
        }

        &.text {
          margin-left: 5px;
          vertical-align: super;
        }
      }

      &:hover {
        color: $primary-color;
      }

      &.active {
        color: $primary-color;
      }
    }
  }

  .content-layout {
    padding-top: $header-height;
    display: flex;
    flex-direction: column;
    background-color: $page-background;

    .page-wrapper {
      margin: 20px;
      //background-color: white;
      border-radius: 5px;
      box-shadow: 0 4px 13px -3px rgba(0, 0, 0, 0.05);
    }
  }

  .ant-layout-sider {
    padding-top: $header-height;

    .MuiSvgIcon-root {
      font-size: 16px;
      margin-right: 8px;
      vertical-align: text-bottom;
    }
  }
}

.product-card-carousel-header {
  margin-bottom: 15px;
}

.product-grid {
  margin: 24px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 10px;
  grid-auto-flow: row;
}

.product-card {
  //width: 225px;
  width: 275px;
  cursor: pointer;
  padding: 10px;
  background: white;
  text-align: center;
  margin: 10px;
  border-radius: 5px;

  &:hover {
    box-shadow: 0 4px 13px -3px rgba(0, 0, 0, 0.15);
  }

  .image {
    margin: 14px;
    margin-top: 36px;
    margin-bottom: 22px;
    height: 200px;
    width: 200px;
    background-color: #FFF;
    object-fit: contain;
  }

  .product-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 14px;
  }

  .wishlist-icon-filled {
    position: absolute;
    height: 24px;
    width: 24px;
    font-size: 16px;
    right: 22px;
    color: $primary-color;
  }

  .wishlist-icon {
    position: absolute;
    height: 24px;
    width: 24px;
    font-size: 16px;
    right: 22px;
  }

  .details-section {
    padding-bottom: 5px;
    min-height: 95px;
    .product-name {
      font-size: 20px;
      line-height: 20px;
      font-weight: 500;
      text-align: center;
    }

    .product-price {
      font-size: 20px;
      line-height: 20px;
      font-weight: 500;
      color: #666666;
      text-align: center;
    }

    .product-code {
      font-size: 20px;
      line-height: 20px;
      font-weight: 500;
      color: #C70E1E;
      text-align: center;
    }

    .wishlist {
      margin: 40px;
    }

    .order-details {
      margin-top: 5px;

      .ant-space {
        width: 100%;

        .ant-space-item {
          width: 50%;

          .items {
            svg {
              font-size: 12px;
            }

            Text {
              font-size: 14px;
            }

            span {
              padding: 0 5px;
            }
          }
        }
      }
    }
  }

}

.breadcrumbs-wrapper {
  position: sticky;
  top: $header-height;
  right: 0;
  left: 0;
  z-index: 1002;
  border-top: 1px solid #cccccc;
  padding: 10px 20px;
  background-color: white;
  box-shadow: 0 4px 13px -3px rgba(0, 0, 0, 0.15);
  margin-bottom: 2px;
}

/*
Dhruv Arya
1-7-21
*/
.category-section {
  padding-top: 15px;
  padding-bottom: 5px;

  .category-name {
    font-size: 20px;
    line-height: 20px;
    font-weight: 500;
    color: #666666;
    text-align: center;
  }
}

.category-carousel-container {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  .pair {
    margin: 5px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .category-card {
    width: 250px;
    height: 300px;
    padding: 8px;
    cursor: pointer;
    padding-top: 32px;
    background: white;
    text-align: center;
    margin-bottom: 25px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:hover {
      box-shadow: 0 4px 13px -3px rgba(0, 0, 0, 0.15);
    }
  }
  .category-image {
    height: 185px;
    width: 185px;
    background-color: #666666;
    margin-bottom: 8px;
  }
}


//reusable css
.full_width {
  width: 100vw;
}
.centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.flex {
  display: flex;
}
.linear {
  display: flex;
  align-items: center;
}
.padding_24 {
  padding: 24px;
}
.thumbnail {
  height: 52px;
  width: 52px;
  object-fit: cover;
}
.cover-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.logo-main {
  height: 24px;
}
.logo {
  height: 38px;
}
.title-black {
  font-size: 20px;
  font-weight: 600;
}
.title-red {
  font-size: 20px;
  font-weight: 600;
  color: $primary-color;
}
.text-desc {
  padding: 0px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}

.button-red {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: $primary-color;
  padding: 8px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 5px;
  text-align: center;
  font-size: 12px;
  color: #FFF;
  &:hover {
    background-color: #DC202B;
  };
  &.active {
    background-color: $primary-color;
  };
}

.action-button-red {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: $primary-color;
  height: 42px;
  text-align: center;
  font-size: 16px;
  color: #FFF;
  &:hover {
    background-color: #DC202B;
  }

  &.active {
    background-color: $primary-color;
  }
}
.divider-black {
  width: 100%;
  background-color: #000;
  padding: 12px;
  text-align: center;
  font-size: 14px;
  color: #FFF;
}

.footer-logo{
  max-height: 16px;
}

.multiple-line-label{
  line-height: 1;
  height: auto !important;
}

.d-flex{
  display: flex;
}
.align-items-center{
  align-items: center;
}
.justify-content-center{
  justify-content: center;
}
.justify-content-between{
  justify-content: space-between;
}

.justify-content-end{
  justify-content: flex-end;
}

.ant-btn-icon-only-img{
  vertical-align: 0px;
}

.table-action-button-icon-img{
  height: 100%;
  &.pb-1{
    padding-bottom: 2px;
  }
}

.success-button{
  color: #fff;
  background-color: $success-color;

  &:hover, &:focus {
    background-color: #ffffff;
    color: $success-color;
    border-color: $success-color;
  }
}

.info-button{
  color: #fff;
  background-color: $info-color;

  &:hover, &:focus, &:active {
    background-color: #ffffff;
    color: $info-color;
    border-color: $info-color;
  }
}

.gray-button{
  color: rgba(#fff, 0.75);
  background-color: $gray;

  &:hover, &:focus, &:active {
    background-color: $gray;
    border-color: $gray;
    color: #fff;
  }
}

.px-2{
  padding-left: 5px;
  padding-right: 5px;
}
.p-1{
  padding: 0.25rem;
}

.mx-3{
  padding-left: 15px;
  padding-right: 15px;
}

.my-5{
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.my-3{
  margin-top: 1rem;
  margin-bottom: 1rem;
}



.h-100{
  height: 100%;
}

.py-3{
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.mx-4{
  margin-left: 25px;
  margin-right: 25px;
}
.no-pointer-events{pointer-events: none;}
.color-inherit{color: inherit !important}
.text-primary{color: $primary-color;}
.cursor-pointer{cursor: pointer;}
.cursor-disabled{cursor: not-allowed;}

.active-button{
  border-color: #000000 !important;
  width: 100px;
  &:hover{
    border-color: $primary-color !important;
  }
}
.inactive-button{
  //border-color: #707070;
  width: 100px;
  &:hover{
    border-color: #d9d9d9 !important;
    color: rgba(0, 0, 0, 0.85);
  }
}

.disabled-row{
  td{
      color: $secondary-color;
    span{
      color: $secondary-color;
    }
    .ant-typography{
      color: $secondary-color;
    }
  }
}

.ql-editor{
  min-height:200px;
}


.table-list-icon{
  height: 16px;
  margin-right: 8px;
  vertical-align: top;
  cursor: pointer;
}

.ant-steps-horizontal .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background-color: $primary-color;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color:#707070 !important;
  opacity: 1 !important; 
}


:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #707070 !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #707070 !important;
}
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-thumb {
  background-color: #aaaaaa;
  border-radius: 20px;
}

.link-button{
  &:hover, &:focus, &:active{
    border: 1px solid transparent;
    background-color: transparent;
  }
}

.modal-spin-container{
  width: 100%;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
