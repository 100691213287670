@import "../shared/style/Variables.scss";
@import '../shared/style/antd/_antd.scss';
@import '../shared/style/main.scss';
@import '../shared/style/utility.scss';
@import "bootstrap-icons/font/bootstrap-icons.scss";

body {
  margin: 0;
  padding: 0;
  font-family: $font-primary !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: $page-background;

  min-height: 100%;
}

#root {
  min-height: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}
