@import "../shared/style/Variables";

.custom-scope-data-table{
  thead{
    td{
      white-space: nowrap;
    }
  }
  td{
    padding: 3px 5px;
  }
}

.total-tab{
  color: rgba(0,0,0,0.95);
}

.remark-button {
  margin-top: 20px;
  cursor: pointer;
  display: inline-block;
  margin: 5px;
  &.active{
    background-color: $primary-color;
    color: white;
  }
}