@import './Mixins.scss';
//@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

// Colors

$primary-color: #E30513;
$secondary-color: #707070;
$link-color: #0000FF;
$light-secondary-color: #6dade6;
$danger-color: #f04134;
$success-color: #7BC142;
$info-color: #A4D9D3;

$black: #0c0b0f;
$white: #ffffff;
$gray: #5F5F5F;
$dark-gray: #787878;
$medium-gray: #a5a5a5;
$light-gray: #d9d9d9;
$blue:#202C55;

$title-font: 'Inter', sans-serif;

$font-family: 'Inter', sans-serif;
$font-color: #333;

$page-background: #F3F4F5;


$sidebar-text-color: $white;

$header-color: $black;

// We have multiple shadow levels to represent perspective
$shadow-0: 0 2px 2px rgba(0, 0, 0, 0.1);
$shadow-1: 2px 4px 6px rgba(0, 0, 0, 0.1);
* {
  transition: box-shadow 0.1s ease-in;
}

$header-height: 77px;
@import './Markdown.scss';

//fonts
$font-primary: 'Inter', sans-serif;
