@import "@recoinsights/shared/style/Variables.scss";

.Benchmarking-role-wrapper{
    background-color: white;
}

.ant-slider-handle {
  border-color: $primary-color;
}
.slider-label > .ant-slider-rail {
  width: 0px;
}
