@import '../shared/style/Variables.scss';


.login {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .login-box-wrapper {
    min-width: 70%;
    background: #fff;
    box-shadow: 0px 7px 15px #0000003B;
    border-radius: 19px;
  }

  .login-page-name {
    font: normal normal normal 36px/40px Inter;
    text-align: center;
    padding: 32px 0;
  }

  .login-form-row {
    //min-height: calc(100vh - 88px);
    .login-text-column {
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .login-text-wrapper {
        //padding: 0 20px;

        .login-prop-image {
          max-height: 400px;
        }

        @media all and (max-width: 768px) {
          .login-prop-image {
            max-height: initial;
          }
        }

        .title {
          font-size: 2rem;
          line-height: 1;
          font-weight: 100;
        }

        .heading {
          font-size: 3.5rem;
          line-height: 1;
          color: #66c6bd;
          font-weight: 100;
        }

        .bar-wrapper {
          display: flex;

          .bar {
            height: 20px;
            flex: 1;
            background: linear-gradient(90deg, #1fa9e1 0 12%, #7bc142 12% 24%, #f48028 24% 36%, #66c6bd 36% 100%);
          }

          .bar-text {
            margin-left: 10px;
          }
        }
      }
    }

    .login-form-wrapper-column {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;

      .login-form-wrapper {
        width: 100%;

        .login-title {
          font: normal normal bold 34px/45px Inter;
          margin-bottom: 52px;
        }

        //width: 400px;
        .form-logo {
          max-height: 45px;
          margin-bottom: 50px;
        }

        .login-form {
          label {
            white-space: initial !important;
          }
        }
      }
    }
  }
}

.popover-on-otp {
  max-width: 500px;

  .ant-popover-arrow {
    display: none !important;
  }
}


@media all and (max-width: 767px) {
  .login {
    .login-box-wrapper {
      .login-page-name {
        font: normal normal normal 26px/35px Inter;
        text-align: left;
        padding: 10px 32px 0px;
      }
    }

    .login-form-row {
      .login-form-wrapper-column {
        margin-top: 30px;

        .login-form-wrapper {
          .login-title {
            font: normal normal bold 23px/33px Inter;
            margin-bottom: 15px;

          }
        }
      }
    }
  }

  .popover-on-otp {
    max-width: 300px;
  }
}
