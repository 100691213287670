@import '../shared/style/Variables.scss';

.section-card {
  background-color: $page-background;

  .section-title {
    position: relative;
    padding-bottom: 5px;
    margin-bottom: 15px;

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      height: 2px;
      width: 50px;
      background-color: $primary-color;
    }
  }

  & + .section-card {
    margin-top: 20px;
  }

  .question-card {
    & + .question-card {
      position: relative;
      margin-top: 15px;
      padding-top: 15px;

      &::before {
        content: '';
        display: block;
        width: 85px;
        height: 2px;
        position: absolute;
        top: 0;
        background-color: $secondary-color;
      }
    }

    .question {
      .fix-width-input {
        display: inline-block;
        min-width: 250px;
      }
    }

    .survey-quill {
      background-color: white;
      background-size: contain;

      &.disabled {
        background-color: #f5f5f5;
        cursor: not-allowed;

        .ql-toolbar {
          pointer-events: none;
        }

        .ql-container {
          color: rgba(0, 0, 0, 0.25);
        }

        * {
          cursor: not-allowed;
        }
      }
    }
  }
}

//floating button

.floating-btn-user {
  width: 50px;
  height: 50px;
  background: #00008B;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 5px 5px 5px #999;
  position: fixed;
  right: 40px;
  bottom: 75px;
  transition: background 0.25s;
}
.floating-btn-user-2 {
  width: 50px;
  height: 50px;
  background-color: $primary-color;
  color: #fff;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 5px 5px 5px #999;
  position: fixed;
  right: 40px;
  bottom: 75px;
  transition: background 0.25s;
}

.icon-save-user {
  font-size: 20px;
  filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(202deg) brightness(109%) contrast(101%);
  cursor: pointer;
}


.button-style-tab-bar {
  .ant-tabs-tab {
    border: 1px solid $primary-color;
    padding: 3px 12px !important;
    transition: 0.3s;

    .ant-tabs-tab-btn {
      transition: 0.3s;
      color: $primary-color !important;
    }

    &.ant-tabs-tab-active {
      background-color: $primary-color;

      .ant-tabs-tab-btn {
        color: $white !important;
      }
    }
  }
}


.remark-button {
  margin-top: 20px;
  cursor: pointer;
  display: inline-block;

  &.active{
    background-color: $primary-color;
    color: white;
  }
}
.ssize{
  font-size: 12px
}
.size-check-box{
  font-size: 12px;
  color:#000000;
}
.comments-drawer-wrapper {
  z-index: 1001;

  .ant-drawer-content {
    .ant-drawer-body {
      padding: 8px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;

      .checkbox-wrapper {
        color: grey !important;
        font-size: 10px !important;
        margin-bottom: 0 !important;

        .check-manager {
          padding: 0 !important;
          margin: 0 !important;
        }

      }

      .comment-wrapper{
        flex-grow: 1;
        overflow: auto;
        .comment-item {
          overflow-y: auto;
          & + .comment-item {
            margin-top: 22px;
          }
          .comment {
            padding: 5px;
            line-height: 1;
            margin-bottom: 3px;
            display: flex;
            justify-content: space-between;
            color: #000000
          }

          .post-date, .post-name {
            padding: 0px 8px;
            color: #AEAEAE;
            line-height: 1.2;
          }
        }
      }

      .footer {
        .input-wrapper{
          margin-bottom: 6px;
          .input {
            width: 100%;
            font-size: 14px;
            line-height: 1.1;
          }
        }

        .btn-reply {
          margin-top: 6px;
          background-color: #E30613 !important;
        }
      }
    }
  }
}

.clickable {
  cursor: pointer;
}

.large-tooltip {
  min-width: 70vw;
  max-width: 500px;
  overflow: auto;
  max-height: 400px;
  padding-bottom: 0;
}

@media screen and (max-width: 500px){
  .large-tooltip {
    max-width: 100vw;
  }
}
