@import '../shared/style/Variables.scss';


.header-title{
  font: normal normal 600 26px/31px $font-primary;
  letter-spacing: 0px;
}

.footer-wrapper {
  background: $primary-color;

  .ant-row {
    margin: 0 !important;
  }

  span {
    color: white;
    font-size: 12px;
  }

  .primary-footer {
    padding: 10px;
    background-color: $primary-color;

    span {
      color: white;
      font-size: 16px;
      line-height: 32px;
    }

    svg {
      color: white;
      vertical-align: sub;
    }

    .links {
      .link {
        padding: 0 20px;
        display: inline-block;
        height: 31px;
        cursor: pointer;
      }
    }
  }

  .secondary-footer {
    padding: 10px;
  }
}

.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}


.home-header-row-2 {
  height: 60px;
  padding: 0 20px;

  .ant-checkbox-wrapper {
    line-height: 30px;
  }

  .ant-row {
    height: 100%;

    svg {
      vertical-align: bottom;
    }

    span, a {
      font-size: 12px;
    }
  }

  .search-bar {
    box-shadow: 0 4px 6px 1px rgba(0, 0, 0, 0.05);

    .ant-input-affix-wrapper {
      padding: 5px 15px;
      border-bottom-left-radius: 5px !important;
      border-top-left-radius: 5px !important;

      input {
        font-size: 14px;
      }
    }

    button {
      border-bottom-right-radius: 5px !important;
      border-top-right-radius: 5px !important;
      height: 36px;
      padding-top: 4px;
    }
  }
}

.home-header-row-3 {
  background-color: black;
  padding: 15px 20px;
  text-align: center;

  .menu-items {
    text-align: center;

    .menu-item {
      padding: 0 10px;
      display: inline-block;
    }
  }

  a {
    color: white;
  }
}


.header-tabs{
  .ant-tabs-nav{
    margin-bottom: 0px !important;
    .ant-tabs-nav-list {
      .ant-tabs-tab{
        &:nth-last-of-type(2){
          margin: 0;
        }
        .ant-tabs-tab-btn{
          color: #fff;
          font-size: 1.1rem;
          .header-icon-img{
            max-height: 16px;
            vertical-align: baseline;
          }
          &:hover{
            color: $primary-color;
            .header-icon-img{fill: $primary-color;}
          }
        }

      }
    }
  }

}

.header-user-text{
  color: #fff;
  display: flex;
  flex-direction: column;
  span{
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1 !important;
  }
}

.btn-spin-wrapper{
  .ant-spin-dot-item{
    background-color: #ffeddf;
  }
}

.page-title{
  font: normal normal normal 26px/31px $font-primary;
  letter-spacing: 0px;
  color: #1A1A1A;
  margin-bottom: 1.5rem;
}

.ant-layout-sider.custom-sidebar{
  .ant-menu-item-selected{
    background-color: transparent !important;
    color: $primary-color;
    &::after{
      border-right: 9px solid $primary-color;
      border-radius: 20px;
      transform: scaleY(0.9);
    }
    &:hover{
      color: $primary-color;
      svg{
        color: $primary-color;
      }
    }
    svg{
      color: $primary-color;
    }
  }
}

.sider-trigger{
  font-size: 1.5rem;
}
.header-logo-wrapper{width: 270px;}


@media only screen and (max-width: 768px) {
  .header-logo-wrapper{
    width: 168px;
    .logo{
      width: 100%;
      height: auto;
    }
  }
  .header-title, .user-role, .user-name{display: none !important;}
}


//icon width and alignment in sidebar
.ant-menu-item .ant-menu-item-icon,
  .ant-menu-submenu-title .ant-menu-item-icon,
    .ant-menu-item .anticon,
      .ant-menu-submenu-title .anticon{
        width: 35px;
        text-align: center;
}

.admin-icon {
  height: 24px;
  object-fit: contain;
}
.menu-icon {
  font-size: 1.5rem !important;
}
.ant-menu-item:hover .admin-icon {
  filter: (
    invert(14%)
    sepia(74%)
    saturate(7387%)
    hue-rotate(352deg)
    brightness(87%)
    contrast(105%)
  );
}
.ant-menu-item-selected .admin-icon {
  filter: (
    invert(14%)
    sepia(74%)
    saturate(7387%)
    hue-rotate(352deg)
    brightness(87%)
    contrast(105%)
  );
}


.name-initial-avatar{
  color: #ffffff !important;
  background-color: #1A1A1A !important;
}
