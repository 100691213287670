@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?5zsct0');
  src:  url('fonts/icomoon.eot?5zsct0#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?5zsct0') format('truetype'),
    url('fonts/icomoon.woff?5zsct0') format('woff'),
    url('fonts/icomoon.svg?5zsct0#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  /*speak: never;*/
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-companies:before {
  content: "\e900";
}
.icon-contactus:before {
  content: "\e901";
}
.icon-content:before {
  content: "\e902";
}
.icon-datacollection_admin:before {
  content: "\e903";
}
.icon-datacollection_user:before {
  content: "\e904";
}
.icon-files:before {
  content: "\e905";
}
.icon-funds:before {
  content: "\e906";
}
.icon-groups:before {
  content: "\e907";
}
.icon-home:before {
  content: "\e908";
}
.icon-insights:before {
  content: "\e909";
}
.icon-othermasters:before {
  content: "\e90a";
}
.icon-permissions:before {
  content: "\e90b";
}
.icon-results:before {
  content: "\e90c";
}
.icon-support:before {
  content: "\e90d";
}
.icon-surveys_admin:before {
  content: "\e90e";
}
.icon-surveys_users:before {
  content: "\e90f";
}
.icon-targets:before {
  content: "\e910";
}
.icon-users:before {
  content: "\e911";
}
