.admin-list-layout {
  .title-bar {
    padding: 15px;

    .icon {
      svg {
        font-size: 30px;
      }
    }

    .ant-typography {
      margin: 0;
    }
  }
}
