.bg-white-tab{
    .ant-tabs-nav{
        margin-bottom: 10px;
        &::before{
            border: none;
        }
        .ant-tabs-tab{
            padding: 1px 0px;
        }
    }
    .ant-tabs-content-holder{
        background-color: #fff;
    }
}
