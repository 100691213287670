@import "@recoinsights/shared/style/Variables.scss";

.Benchmarking-role-wrapper{
    background-color: white;
}

.InputWeight{
    font-weight: 600;
    color:#000000 !important;
}

.unitClass{
    font-size: 12px;
    color: #AFAFAF;
}
.btnwidth{
    width: 4px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.bottomLine{
    border-bottom:1px solid #000;
}
.bottomRight{
    border-right:1px solid #000;
}
.colorClass{
    width: 90px;
    text-align: center;
}

.inputkpi{
    height: 60px;
}

.bottomLeft{
    border-left:1px solid #000;
}

.paraKpi{
    display: flex;
    margin: auto;
}
::selection {
    color: whitesmoke;
    background: red;
  }
