@import "../shared/style/Variables.scss";
.ant-carousel{
  .slick-prev, .slick-next{
    //background: $primary-color;
    color: $primary-color;
    z-index: 1;
    width: 35px;
    height: 35px;

    &:hover, &:focus{
      opacity: 0.7;
      color: $primary-color;
    }
  }

  .slick-prev{
    left: 0;
    &::before{
      content: "\00ab";
      font-size: 35px;
      line-height: 1;
    }
  }
  .slick-next{
    right: 0;
    &::before{
      content: "\00bb";
      font-size: 35px;
      line-height: 1;
    }
  }
}

.doc-card{
  filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.3));
  border-radius: 4px;
}


.ant-carousel{
  .slick-dots{
    li{
      button{
        background-color: $primary-color;
        opacity: 0.5;
      }
      &.slick-active{
        button{
          background-color: $primary-color;
          opacity: 1;
        }
      }
    }
    &.slick-dots-bottom{
      bottom: 0;
    }
  }
}

.site-card-wrapper{
	//padding:0px;
	.image-space{
		text-align: center;
	}
}



.carousel-card{
  padding: 25px;
  img{
    //width: 100%;
    //height: 100%;
    //object-fit: cover;
  }
}
